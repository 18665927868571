import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {Typography,Fab,Toolbar,TextField,FormLabel} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
// import MobileStepper from '@material-ui/core/MobileStepper';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
//import {Link} from 'react-router-dom';

import classNames from 'classnames';
import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';

import theme,{styles} from '../layout/theme';
import Utils from '../api/api';
// import {CardMedia} from '@material-ui/core/Card';

// import WebConfig from '../api/config';

// var config = WebConfig.production;
// if(window.location.hostname==='localhost'){
//   config = WebConfig.development;
// }

export class ProductList extends Component {
  constructor(props){
    super(props);
    this.state = {
      // data:[],
      categories:[],
      // category:'',
      // failed:false,
      // message:'',
      // activeStep:0,
      // totalSteps:1,
      // lastId:0,
      // firstId:0,
      // ranges: [],
      // keyword:''
      keyword: '',
      searchClick: false
    }
  }
  componentDidMount(){
    var $this = this;
    Utils.getListData('categories',1,100,'0,0',null,1,function(data){
      //console.log(data);
      if(data.status=='success'&&data.results.length){
        $this.setState({categories:data.results});
      }
    });
    // Utils.products(1,config.itemPerPage,$this.state.firstId + ',' + $this.state.lastId,$this.state.category,1,function(data){
    //   //console.log(data);
    //   if(data.status=='success'&&data.results.length){
    //     //console.log(data);
    //     var first = data.results[0]._id;
    //     var last = data.results[data.results.length-1]._id;
    //     $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first},function(){
    //       var arr = $this.state.ranges;
    //       for(var i=0,len=$this.state.totalSteps;i<len;i++){
    //         arr.push({});
    //       }
    //       arr[0] = first + ',' + last;
    //       $this.setState({ranges:arr});
    //     });
    //   }
    // });
  }
  handleFilter(e){
    //console.log(e.target);
    // var $this = this;
    // this.setState({category:e.target.value},function(){
    //   let filter;
    //   if($this.state.keyword!==''){
    //     filter=$this.state.category+','+$this.state.keyword;
    //   }else{
    //     filter=$this.state.category;
    //   }
    //   Utils.products(1,config.itemPerPage,'0,0',filter,1,function(data){
    //     if(data.status=='success'&&data.results.length){
    //       //console.log(data);
    //       var first = data.results[0]._id;
    //       var last = data.results[data.results.length-1]._id;
    //       $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first,activeStep:0},function(){
    //         var arr = [];
    //         for(var i=0,len=$this.state.totalSteps;i<len;i++){
    //           arr.push({});
    //         }
    //         arr[0] = first + ',' + last;
    //         $this.setState({ranges:arr});
    //       });
    //     }else{
    //       $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:0,firstId:0,activeStep:0},function(){
    //         $this.setState({ranges:[]});
    //       });
    //     }
    //   });
    // });
  }
  handleSearch(e){
    //console.log(e.target);
    // var $this = this;
    // Utils.products(1,config.itemPerPage,'0,0',$this.state.category+','+$this.state.keyword,1,function(data){
    //   if(data.status=='success'&&data.results.length){
    //     var first = data.results[0]._id;
    //     var last = data.results[data.results.length-1]._id;
    //     $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first,activeStep:0},function(){
    //       var arr = [];
    //       for(var i=0,len=$this.state.totalSteps;i<len;i++){
    //         arr.push('');
    //       }
    //       arr[0] = first + ',' + last;
    //       $this.setState({ranges:arr});
    //     });
    //   }else{
    //     $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:0,firstId:0,activeStep:0},function(){
    //       $this.setState({ranges:[]});
    //     });
    //   }
    // });
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  // onStepNext=()=>{
  //   var $this = this;
  //   this.setState({
  //     activeStep: $this.state.activeStep + 1
  //   },function(){
  //     Utils.products($this.state.activeStep,config.itemPerPage,$this.state.firstId +','+$this.state.lastId,$this.state.category+','+$this.state.keyword,1,function(data){
  //       if(data.status=='success'&&data.results.length){
  //         var first = data.results[0]._id;
  //         var last = data.results[data.results.length-1]._id;
  //         var arr = $this.state.ranges;
  //         $this.setState({data:data.results,lastId:last,firstId:first},function(){
  //           arr[$this.state.activeStep] = first + ',' + last;
  //           $this.setState({ranges:arr});
  //         });
  //       }
  //     });
  //   });
  // }
  // onStepBack=()=>{
  //   var $this = this;
  //   this.setState({
  //     activeStep: this.state.activeStep - 1
  //   },function(){
  //     console.log($this.state.ranges[$this.state.activeStep]);
  //     Utils.products($this.state.activeStep,config.itemPerPage,$this.state.ranges[$this.state.activeStep],$this.state.category+','+$this.state.keyword,0,function(data){
  //       if(data.status=='success'&&data.results.length){
  //         //console.log(data);
  //         var last = data.results[data.results.length-1]._id;
  //         $this.setState({data:data.results,lastId:last});
  //       }
  //     });
  //   });
  // }
  handleAdd(){
    this.context.router.history.push('/product/add');
  }
  // handleEdit(obj){
  //   this.context.router.history.push('/product/edit/'+obj._id);
  // }
  // handleDelete(obj){
  //   var $this = this;
  //   Utils.product_delete(obj,function(res){
  //     //console.log(res);
  //     if(res.status=='success'){
  //       let filter='';
  //       if($this.state.keyword!==''){
  //         filter=$this.state.keyword;
  //       }
  //       Utils.products(1,config.itemPerPage,'0,0',filter,1,function(data){
  //         if(data.status=='success'&&data.results.length){
  //           var first = data.results[0]._id;
  //           var last = data.results[data.results.length-1]._id;
  //           $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first,activeStep:0},function(){
  //             var arr = [];
  //             for(var i=0,len=$this.state.totalSteps;i<len;i++){
  //               arr.push('');
  //             }
  //             arr[0] = first + ',' + last;
  //             $this.setState({ranges:arr});
  //           });
  //         }
  //       });
  //     }else{
  //       $this.setState({failed:true,message:res.message});
  //     }
  //   });
  // }
  render(){
    //const classes = this.props.classes;
    //console.log(this.state.categories)
    const $this = this;
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Tên',value:'name'},{title:'SEO Url',value:'url'},{title:'Hình',value:'photo'},{ title: 'Nhóm', value: 'group'},{title:'Giá',value:'price'},{title:'Giá bán',value:'priceSale'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.nameVN,
          url: <div>{n._id}<br/><br/>{n.url}</div>,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.image !== '' && n.image !== undefined ? Utils.fixImagePath(n.image) : Utils.fixImagePath('/data/upload/images/no_photo.png',{width:100,height:60})} title={n.nameVN} /></div>,
          group: $this.state.categories.length&&$this.state.categories.map(x=>{return x._id==n.category?x.nameVN:''}),
          price: n.price,
          priceSale: n.priceSale
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục sản phẩm</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <FormLabel className={classes.label_shrink}>Nhóm sản phẩm</FormLabel>
              <select className={'select ' + classes.select}  value={this.state.category} onChange={(event)=>this.handleFilter(event)}>
                <option value="" key={0}>...</option>
                {this.state.categories.map((item,index)=>(
                  <option key={index+1} value={item._id}>{item.nameVN}</option>
                ))}
              </select>
            </FormGroup>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='products' apiSingle='product' apiTable='products' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
          {/* <Table className="table-responsive">
            <TableHead>
              <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Tên</TableCell>
                <TableCell>Id/SEO URL</TableCell>
                <TableCell>Hình</TableCell>
                <TableCell>Nhóm</TableCell>
                <TableCell>Giá</TableCell>
                <TableCell>Giá bán</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((n,index) => {
                return (
                  <TableRow key={n._id}>
                    <TableCell>{parseInt(this.state.activeStep*config.itemPerPage)+index+1}</TableCell>
                    <TableCell>{n.nameVN}</TableCell>
                    <TableCell>
                    {n._id}<br/><br/>{n.url}
                    </TableCell>
                    <TableCell>
                      <CardMedia
                        className={classes.thumbnail}
                        image={n.image!==''&&n.image!==undefined?Utils.fixImagePath(n.image):Utils.fixImagePath('/data/images/no_photo.png')}
                        title={n.nameVN}
                      />
                    </TableCell>
                    <TableCell>
                    {
                      (this.state.categories.map(function(obj){
                        if(obj._id===n.category){
                          return obj.nameVN;
                        }
                        return '';
                      }))
                    }
                    </TableCell>
                    <TableCell>{Utils.formatCurrency(parseFloat(n.price))}</TableCell>
                    <TableCell>{Utils.formatCurrency(parseFloat(n.priceSale))}</TableCell>
                    <TableCell className={classes.right}>
                      <IconButton className={classes.button} aria-label="Cập nhật" onClick={()=>this.handleEdit(n)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton className={classes.button} aria-label="Xóa" onClick={()=>this.handleDelete(n)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <MobileStepper
            variant="dots"
            steps={this.state.totalSteps}
            position="static"
            activeStep={this.state.activeStep}
            nextButton={
              <Button onClick={this.onStepNext} disabled={this.state.activeStep === (this.state.totalSteps-1)}>
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button onClick={this.onStepBack} disabled={this.state.activeStep === 0}>
                <KeyboardArrowLeft />
              </Button>
            }
            className={classes.paging}
          /> */}
        </Paper>
      </div>
    )
  }
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired,
};
ProductList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ProductList);
