import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import {
  TextField,
  Typography,
  Button,
  FormLabel,
  Toolbar,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { AppBar, Tabs, Tab, FormGroup, FormControl, Slide, Dialog } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import TabContainer from "./shares/react_tab_container";
import language from "../layout/language";
import Media from "./media";
import ImageControl from "./shares/react_image_control";

import classNames from "classnames";
import Utils from "../api/api";
import theme, { styles } from "../layout/theme";



// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/ionicons.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/css/third_party/ionicons.min.css";

import FroalaEditor from "react-froala-wysiwyg";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    //var obj = { image: "", title: "", desc: "", urlVN: "",urlEN: "" };
    this.state = {
      categories: [],
      source_properties: [],
      source_properties_value: [],
      // properties_id: '',
      // properties_id_label: '',
      // properties_value: '',
      properties: {},
      product: {
        nameVN: "",
        nameEN: "",
        //nameKR:'',
        detailVN: "",
        detailEN: "",
        //detailKR:'',
        descriptionVN: "",
        descriptionEN: "",
        //descriptionKR:'',
        category: "",
        variants: [],
        properties: [],
        image: "",
        images: [],
        price: "",
        priceSale: "",
        urlVN: "",
        urlEN: "",
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        nameVN_error: false,
        nameEN_error: false,
        //nameKR_error: false,
        detailVN_error: false,
        detailEN_error: false,
        //detailKR_error: false,
        category_error: false,
      },
      failed: false,
      message: "",
      isUpdate: false,
      tab: 0,
      open: false,
      openMedia: false,
      options: { empty: "empty" },
    };
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData("categories", 1, 100, "0,0", null, 1, function (data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ categories: data.results });
      }
    });

    var propertytable = [];
    Utils.getListData("properties", 1, 100, "0,0", null, 1, function (data) {
      //console.log(data);
      if (data.status === "success") {
        var obj = {};
        data.results.map((x) => {
          x.checked = true;
          obj[x._id] = "";
          propertytable.push(x);
        });
        var product = $this.state.product;
        product.properties = propertytable;
        $this.setState(
          {
            source_properties: data.results,
            properties: obj,
            product: product,
          },
          function () {
            //console.log($this.state.product);
          }
        );
      }
    });

    Utils.getBlockContents(function (options) {
      $this.setState({ options: options });
    });

    if (params.id !== undefined) {
      $this.setState(
        {
          isUpdate: true,
        },
        function () {
          Utils.getSingleData("product", params.id, function (res) {
            if (res.status == "success" && res.results !== undefined) {
              var data = $this.state.product,
                item = res.results;
              for (var property in item) {
                if (item.hasOwnProperty(property)) {
                  data[property] = item[property];
                }
              }
              data._id = item._id;
              if (data.urlVN === undefined) data.urlVN = "";
              if (data.urlEN === undefined) data.urlEN = "";
              if (data.priceSale === undefined || data.priceSale === "")
                data.priceSale = data.price;
              if (data.image.length === 0) data.image.push("");
              if (data.properties === undefined || data.properties.length === 0)
                data.properties = propertytable;

              $this.setState({ product: data }, function () {
                //console.log($this.state.banner);
              });
            }
          });
        }
      );
    }
  }
  onEditorStateChange(field, value) {
    var product = this.state.product,
      error = this.state.error;
    product[field] = value;
    //console.log(JSON.stringify(value));
    var locale = field.replace("detail", "");
    var temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = value;
    // Retrieve the text property of the element (cross-browser support)
    var text =
      temporalDivElement.textContent || temporalDivElement.innerText || "";
    product["description" + locale] = text.substring(0, 200);

    error[field + "_error"] = value == "";
    this.setState({ product: product, error: error });
  }
  addMoreItem() {
    var product = this.state.product,
      data = product.images,
      l = data.length;
    if (l < 10) {
      data.push("");
      product.images = data;
      this.setState({ product: product }, function () {});
    }
  }
  addMoreItems() {
    //console.log('click ...');
    var $this = this,
      product = this.state.product,
      data = product.images;

    this.state.files.map((x) => {
      data.push({ src: x.fullpath, desc: "" });
    });
    product.images = data;
    this.setState({ product: product }, function () {
      $this.handleCloseMedia();
    });
  }
  storeSeleted(items) {
    this.setState({ files: items });
  }
  openMediaDialog() {
    this.setState({ openMedia: true });
  }
  removeItem(index) {
    //console.log(index);
    var product = this.state.product,
      data = product.images,
      l = data.length;
    if (l > 1) {
      data.splice(index, 1);
      product.images = data;
      this.setState({ product: product }, function () {});
    }
  }
  handleImageChange(index, value) {
    //console.log(value);
    var $this = this,
      product = $this.state.product;
    product.images[index] = value;
    this.setState({ product: product });
  }
  handleFieldChange(e) {
    var $this = this,
      product = $this.state.product;
    product[e.target.id] = e.target.value;
    if (e.target.type == "textarea") {
      product[e.target.id] = e.target.value.substring(0, 200);
    }
    //console.log(e.target.value);
    this.setState({ product: product });
  }
  handleValidate(e, flag) {
    var $this = this,
      product = this.state.product,
      obj = this.state.error;

    product[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + "_error";
    //console.log(p);
    if (e.target.value.trim() == "" || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if (e.target.id === "nameVN") {
      var urlVN = Utils.getFriendlyURL(e.target.value);
      product.urlVN = urlVN;
    }
    if (e.target.id === "nameEN") {
      var urlEN = Utils.getFriendlyURL(e.target.value);
      product.urlEN = urlEN;
    }
    if (e.target.id.indexOf("price") !== -1) {
      product[e.target.id] = parseFloat(e.target.value.trim());
    }

    this.setState({ product: product, error: obj }, () => {});
  }
  // selectImg(index,value){
  //   var $this = this,
  //       product = this.state.product;
  //   product.images[index] = value;
  //   this.setState({product:product},function(){
  //     $this.handleClose();
  //   });
  // }
  handleCover(value) {
    var $this = this,
      product = this.state.product;
    product.image = value;
    this.setState({ product: product });
  }
  _save() {
    var $this = this;
    var data = this.state.product;

    //convert price to number
    data.price = parseFloat(data.price);
    data.priceSale = parseFloat(data.priceSale);

    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update("product", data, function (res) {
        //console.log(res);
        if (res.status == "success") {
          $this.context.router.history.push("/product/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message),
          });
        }
      });
    } else {
      Utils._add("product", data, function (res) {
        if (res.status == "success") {
          $this.context.router.history.push("/product/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message),
          });
        }
      });
    }
  }
  handleSave(e, flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({ isUpdate: true });
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch (flag) {
        case "vn":
          obj["nameVN_error"] = this.state.product.nameVN === "";
          obj["detailVN_error"] = this.state.product.detailVN === "";
          obj["category_error"] = this.state.product.category === "";
          obj["vn"] =
            obj["nameVN_error"] ||
            obj["category_error"] ||
            obj["detailVN_error"];
          this.setState({ error: obj }, function () {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        case "en":
          obj["nameEN_error"] = this.state.product.nameEN === "";
          obj["detailEN_error"] = this.state.product.detailEN === "";
          obj["en"] = obj["nameEN_error"] || obj["detailEN_error"];
          this.setState({ error: obj }, function () {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['nameKR_error'] = this.state.product.nameKR === '';
        //   obj['detailKR_error'] = this.state.product.detailKR === '';
        //   obj['kr'] = obj['nameKR_error']||obj['detailKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push("/product/");
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleProperty(e) {
    try {
      // var opt = e.target.options[e.target.selectedIndex],
      //   arr = opt.getAttribute('data-value').split(','),
      //   id_arr = this.state.properties_id.split(','),
      //   title_arr = this.state.properties_id_label.split(',');
      // let i = id_arr.indexOf(e.target.value),
      //   x = title_arr.indexOf(opt.innerText);
      // if (i != -1) {
      //   id_arr = id_arr.splice(i, 1);
      // }
      // if (x != -1) {
      //   title_arr = title_arr.splice(i, 1);
      // }
      // id_arr.push(e.target.value.trim());
      // title_arr.push(opt.innerText.trim());
      // this.setState({properties_id: id_arr.join(), properties_id_label: title_arr.join(), source_properties_value: arr},function(){
      // });
    } catch (e) {
      this.setState({
        properties_id: "",
        properties_id_label: "",
        source_properties_value: [],
      });
    } finally {
    }
  }
  handlePropertyValue(e, item) {
    var $this = this,
      product = $this.state.product,
      p = $this.state.properties;

    if (typeof item === "object") {
      //p[e.target.id] = {id:e.target.id};
      // p[e.target.id]['nameVN'] = item.nameVN;
      // p[e.target.id]['nameEN'] = item.nameEN;
      // p[e.target.id]['nameKR'] = item.nameKR;
      //p[e.target.id]['value'] = e.target.value;
      p[e.target.id] = e.target.value;
    }
    if (item === "price") {
      p["price"] = e.target.value;
    }
    if (item === "status") {
      p["status"] = e.target.checked;
    }
    if (p["price"] === undefined || p["price"] === "" || p["price"] === 0) {
      p["price"] = product.priceSale;
    }
    if (p["status"] === undefined) {
      p["status"] = true;
    }

    this.setState({ properties: p }, function () {
      //console.log($this.state.properties);
    });
    // var v_arr = this.state.properties_value.split(',');
    // var product = this.state.product;
    // let i = v_arr.indexOf(e.target.value);
    // if (i != -1) {
    //   v_arr = v_arr.splice(i, 1);
    // }
    // v_arr.push(e.target.value);
    // this.setState({
    //   properties_value: v_arr.join()
    // }, function() {
    //   var obj = {};
    //   let a = this.state.properties_id_label.split(','),
    //     v = this.state.properties_value.split(','),
    //     i = this.state.properties_id.split(',');
    //   obj.title = a[a.length - 1];
    //   obj.value = v[v.length - 1];
    //   obj.id = i[i.length - 1];
    //   const arr = [...this.state.properties];
    //   for (let x = 0, l = arr.length; x < l; x++) {
    //     if (arr[x].id == obj.id) {
    //       arr.splice(x, 1);
    //       break;
    //     }
    //   }
    //   arr.push(obj);

    //   this.setState({
    //     properties: arr
    //   });
    // });
  }
  handleDeleteProperty(data) {
    const arr = [...this.state.properties];
    const obj = arr.indexOf(data);
    //var product = this.state.product;
    arr.splice(obj, 1);
    //product.properties = arr;
    this.setState({ properties: arr });
  }
  addVariations() {
    var $this = this,
      product = this.state.product,
      variants = product.variants,
      variant = {},
      id = variants.length,
      obj = this.state.properties;

    variant.id = id;
    variant.values = obj;

    // Validate input
    var isValid = true;
    var src = this.state.source_properties;
    src.map((x) => {
      if (x.checked) {
        if (variant.values[x._id] === undefined || variant.values[x._id] === "")
          isValid = false;
      }
    });

    if (!isValid) {
      alert("Vui lòng chọn đủ thông tin các thuộc tính của sản phẩm");
      return true;
    }

    var isExist = false;
    for (var i = 0, len = variants.length; i < len; i++) {
      isExist = Utils.variantExist(variant, variants[i]);
      if (isExist) {
        alert("Biến thể đã có");
        return;
      }
    }

    variants.push(variant);
    this.setState({ product: product }, function () {
      $this.setState(
        {
          properties: {},
          // properties_id: '',
          // properties_id_label: '',
          // properties_value: ''
        },
        function () {
          // var data = $this.state.product.variants[0].values;
          // for(var propName in data) {
          //   if(data.hasOwnProperty(propName)) {
          //     var propValue = data[propName];
          //     console.log(propValue);
          //   }
          // }
        }
      );
    });
  }
  handleUpdateVariant(event, field, index) {
    var $this = this,
      product = $this.state.product,
      variants = $this.state.product.variants;
    // const arr = [...variants];
    // const obj = arr.indexOf(item);
    // arr.splice(obj, 1);
    //variants[index] = event.target.value;
    //console.log(event.target.checked);

    if (field === "status") {
      variants[index].values[field] = event.target.checked;
    }
    if (field === "price") {
      variants[index].values[field] = event.target.value;
    }
    product.variants = variants;

    this.setState({ product: product });
  }
  handleSwitchProperty(item, index) {
    var $this = this,
      source = $this.state.source_properties;
    source[index].checked = !item.checked;
    var propertytable = [];
    for (var i = 0, len = source.length; i < len; i++) {
      if (source[i].checked) propertytable.push(source[i]);
    }
    var product = $this.state.product;
    product.properties = propertytable;
    $this.setState(
      { source_properties: source, product: product },
      function () {
        //console.log($this.state.source_properties);
      }
    );
  }
  handleCloseMedia = () => {
    this.setState({ openMedia: false, files: [] });
  };
  handleDragStart(e, item, index) {
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index,
    });
  }
  handleDragOver(e) {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEnd(e) {
    //console.log(e);
  }
  handleDrop(e, item, index) {
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
      product = this.state.product,
      data = this.state.product.images,
      old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex, 1);
    data.splice(index, 0, old);
    //console.log(data);
    // for(var i=0,len=data.length;i<len;i++){
    //   data[i].order = parseInt($this.state.activeStep*config.itemPerPage)+i
    // }
    product.images = data;

    this.setState(
      {
        product: product,
      },
      function () {
        //console.log(product.images);
      }
    );
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const product = this.state.product;

    const editorConfig = Utils.setupEditor( "products", this.state.options);
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa sản phẩm
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.vn && classes.hide
                )}
              >
                {language.VN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <FormGroup className={classes.formRowInlineTop}>
                <FormControl className={classes.col6}>
                  <TextField
                    id="nameVN"
                    label={language.VN.name}
                    className={classes.textField}
                    value={product.nameVN}
                    onChange={(event) => this.handleValidate(event, "vn")}
                    onBlur={(event) => this.handleValidate(event, "vn")}
                    required={true}
                    error={this.state.error.nameVN_error}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    id="urlVN"
                    label="Link [SEO]"
                    className={classes.textField}
                    value={product.urlVN}
                    disabled={true}
                    onChange={(event) => this.handleFieldChange(event)}
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <FormGroup className={classes.formRow}>
                    <Typography className={classes.label} type="caption">
                      Nhóm sản phẩm
                    </Typography>
                    <select
                      className={classes.select + " " + classes.textField}
                      id="category"
                      value={product.category}
                      onChange={(event) => this.handleFieldChange(event)}
                    >
                      <option value="" key={0}>
                        ...
                      </option>
                      {this.state.categories.map((item, index) => {
                        return (
                          <option key={index + 1} value={item._id}>
                            {item.nameVN}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>
                  <TextField
                    id="price"
                    type="number"
                    label={language.VN.price}
                    className={classes.textField}
                    value={product.price}
                    onChange={(event) => this.handleFieldChange(event)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="priceSale"
                    type="number"
                    label={language.VN.priceSale}
                    className={classes.textField}
                    value={product.priceSale}
                    onChange={(event) => this.handleFieldChange(event)}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
                <FormControl
                  className={classes.col6}
                  style={{ paddingRight: 0 }}
                >
                  <ImageControl
                    style={{ paddingTop: 0 }}
                    label="Hình đại diện"
                    value={product.image}
                    handleChange={(image) => this.handleCover(image)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => this.openMediaDialog()}
                  >
                    Thêm hình(s)
                    <AddIcon />
                  </Button>
                  <div className="item-image-grid">
                    {product.images.map((x, i) => {
                      return (
                        <div
                          key={i}
                          className={classNames(
                            "item-image-wrapper",
                            classes.drag_item
                          )}
                          draggable={true}
                          onDragStart={(e) => this.handleDragStart(e, x, i)}
                          onDragOver={(e) => this.handleDragOver(e)}
                          onDragEnd={(e) => this.handleDragEnd(e)}
                          onDrop={(e) => this.handleDrop(e, x, i)}
                        >
                          <IconButton
                            color="secondary"
                            aria-label="delete"
                            size="small"
                            onClick={() => this.removeItem(i)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <ImageControl
                            value={x.src}
                            handleChange={(value) =>
                              this.handleImageChange(i, value)
                            }
                          />
                          <TextField
                            label="Tiêu đề"
                            className={classes.textField}
                            value={x.desc}
                            onChange={(event) => this.handleIDChange(i, event)}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </FormControl>
              </FormGroup>
              {this.state.source_properties.length && (
                <FormGroup className={classes.formRow}>
                  <Table className="table-responsive table-variants">
                    <TableHead>
                      <TableRow>
                        {this.state.source_properties.map((item, index) => (
                          <TableCell key={index + 1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item._id}
                                  checked={item.checked}
                                  onChange={() =>
                                    this.handleSwitchProperty(item, index)
                                  }
                                />
                              }
                              label={item.nameVN}
                            />
                          </TableCell>
                        ))}
                        <TableCell
                          key={this.state.source_properties.length + 1}
                        >
                          <FormLabel className={classes.labelCell}>
                            Giá bán
                          </FormLabel>
                        </TableCell>
                        <TableCell
                          key={this.state.source_properties.length + 2}
                        >
                          <FormLabel className={classes.labelCell}>
                            Tình trạng
                          </FormLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {product.variants.map((x, i) => {
                        return (
                          <TableRow key={i}>
                            {this.state.source_properties.map((item, index) => (
                              <TableCell key={index + 1}>
                                {item.checked && x.values[item._id]}
                              </TableCell>
                            ))}
                            <TableCell
                              key={this.state.source_properties.length + 1}
                            >
                              <FormLabel className={classes.labelCell}>
                                <TextField
                                  defaultValue={x.values["price"].toString()}
                                  onChange={(event) =>
                                    this.handleUpdateVariant(event, "price", i)
                                  }
                                  type="number"
                                  className={classes.textField}
                                  margin="normal"
                                />
                              </FormLabel>
                            </TableCell>
                            <TableCell
                              key={this.state.source_properties.length + 2}
                            >
                              <Checkbox
                                defaultChecked={x.values["status"]}
                                onChange={(event) =>
                                  this.handleUpdateVariant(event, "status", i)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow key={product.variants.length}>
                        {this.state.source_properties.map((item, index) => (
                          <TableCell key={index + 1}>
                            <select
                              id={item._id}
                              disabled={!item.checked}
                              className={classNames(
                                classes.select + " " + classes.textField,
                                item.checked ? "" : " disabled"
                              )}
                              onChange={(event) =>
                                this.handlePropertyValue(event, item)
                              }
                            >
                              <option value="" key={0}>
                                ...
                              </option>
                              {item.valueVN.split(",").map((x, index) => (
                                <option
                                  className={classes.selectOption}
                                  key={index + 1}
                                  value={x.trim()}
                                >
                                  {x.trim()}
                                </option>
                              ))}
                            </select>
                          </TableCell>
                        ))}
                        <TableCell
                          key={this.state.source_properties.length + 1}
                        >
                          <FormLabel className={classes.labelCell}>
                            <TextField
                              id="priceVariant"
                              type="number"
                              className={classes.textField}
                              margin="normal"
                              onChange={(event) =>
                                this.handlePropertyValue(event, "price")
                              }
                            />
                          </FormLabel>
                        </TableCell>
                        <TableCell
                          key={this.state.source_properties.length + 2}
                        >
                          <Checkbox
                            defaultChecked={true}
                            id="status"
                            onChange={(event) =>
                              this.handlePropertyValue(event, "status")
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell />
                        <TableCell
                          colSpan={this.state.source_properties.length}
                        >
                          <FormGroup className={classes.formRow}>
                            <Button
                              variant="contained"
                              color="secondary"
                              aria-label="add"
                              onClick={() => this.addVariations()}
                            >
                              Thêm biến thể
                              <AddIcon />
                            </Button>
                          </FormGroup>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </FormGroup>
              )}
              <TextField
                id="descriptionVN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                className={classes.textField}
                value={product.descriptionVN}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailVN_error}
              >
                {language.VN.detail}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfig}
                model={product.detailVN}
                onModelChange={(value) =>
                  this.onEditorStateChange("detailVN", value)
                }
              />
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={(event) => this.handleCancel(event)}
                >
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => this.handleSave(event, "vn")}
                >
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.en && classes.hide
                )}
              >
                {language.EN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <TextField
                id="nameEN"
                label={language.EN.name}
                className={classes.textField}
                value={product.nameEN}
                onChange={(event) => this.handleValidate(event, "en")}
                onBlur={(event) => this.handleValidate(event, "en")}
                required={true}
                error={this.state.error.nameEN_error}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="urlEN"
                label="Link [SEO]"
                className={classes.textField}
                value={product.urlEN}
                disabled={true}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                id="descriptionEN"
                rows="5"
                multiline={true}
                label="Meta Description (max-length: 200 characters)"
                className={classes.textField}
                value={product.descriptionEN}
                onChange={(event) => this.handleFieldChange(event)}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailEN_error}
              >
                {language.EN.detail}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfig}
                model={product.detailEN}
                onModelChange={(value) =>
                  this.onEditorStateChange("detailEN", value)
                }
              />
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={(event) => this.handleCancel(event)}
                >
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => this.handleSave(event, "en")}
                >
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        <Dialog
          fullScreen
          open={this.state.openMedia}
          onClose={this.handleCloseMedia}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogMedia}>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={this.handleCloseMedia}>
                {language.VN.btnCancel}
              </Button>
              <Button
                variant="contained"
                onClick={() => this.addMoreItems()}
                color="primary"
              >
                {language.VN.btnSave}
              </Button>
            </FormGroup>
            <Media onMultiSelectChange={(items) => this.storeSeleted(items)} />
          </div>
        </Dialog>
      </div>
    );
  }
}

ProductDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
ProductDetail.contextTypes = {
  router: PropTypes.object,
};

export default withStyles(styles)(ProductDetail);
